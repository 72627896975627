<template>
  <div class="auth-wrapper auth-v2">
    <ParticleAnimation />

    <div class="auth-left" ref="authParentBox">
      <div class="auth-box" ref="authChildBox">
        <b-link class="">
          <img
            src="@/assets/images/svg/logo.svg"
            style="width: 270px; padding-top: 8px"
          />
        </b-link>
        <img src="@/assets/images/svg/teethlogin.png" class="teeth" alt="" />
        <img src="@/assets/images/svg/vector.png" class="vectors" alt="" />
        <p>Efficiency & Quality -<br />let AI revolutionize your work!</p>
      </div>
    </div>
    <div class="auth-right">
      <!-- <video class="bg-anim" loop autoplay muted src="@/assets/images/video/bg.mp4"></video> -->
      <div class="auth-detail">
        <div class="auth-detail-logo">
          <h2>{{ $t("Welcome") }}</h2>
          <!-- <h1><span>DENTAL</span>TWIN</h1> -->
          <!-- <img
            src="@/assets/images/svg/logoDark.svg"
            class="light"
            style="width: 270px; padding-top: 8px"
          />
          <img
            src="@/assets/images/svg/logo.svg"
            class="dark"
            style="width: 270px; padding-top: 8px"
          /> -->
        </div>
        <p>
          Enter your email and we'll send you instructions to reset your password
        </p>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button type="submit" variant="primary" block>
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <div class="create-acc">
          <b-link :to="{ name: 'login' }"
            ><feather-icon icon="ChevronLeftIcon" /> Back to login</b-link
          >
        </div>

        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ParticleAnimation from "@/components/ParticleAnimation.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    ParticleAnimation
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    setWidth() {
      const parentWidth = this.$refs.authParentBox.offsetWidth;
      const parentheight = this.$refs.authParentBox.offsetHeight;
      this.$refs.authChildBox.style.width = `${parentWidth}px`;
      this.$refs.authChildBox.style.height = `${parentheight}px`;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
